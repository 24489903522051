
import Vue from "vue";
import gmapsInit from "@/services/mapService";
import notification from "@/services/notificationService";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";

export default Vue.extend({
  name: "LiveMap",
  components: { NoTenantSelectedMessage },
  data() {
    return {
      map: undefined as undefined | any,
      markers: [] as any,
      infoWindows: [] as any,
      refreshInterval: 5000,
      filters: {
        vehicle: undefined as undefined | number
      },
      backgroundRefresh: undefined as undefined | NodeJS.Timeout
    };
  },
  computed: {
    vehiclesLocation() {
      return this.$store.state.vehiclesLocation;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  watch: {
    vehiclesLocation: function () {
      this.updateMarkerPositions();
    },
    selectedTenant: async function () {
      this.initializeView();
    }
  },
  beforeDestroy() {
    if (this.backgroundRefresh) {
      clearInterval(this.backgroundRefresh);
    }
  },
  created() {
    this.initializeView();
  },
  methods: {
    async initializeView() {
      if (this.selectedTenant) {
        await this.getVehicleLocations();
        if (this.$route.query.selectedVehicle) {
          this.filters.vehicle = parseInt(
            this.$route.query.selectedVehicle.toString()
          );
        }
        this.loadMap();
        this.setBackgroundRefresh();
      }
    },
    async getVehicleLocations() {
      await this.$store.dispatch("getVehiclesLocation", {
        selectedTenant: this.selectedTenant
      });
    },
    setBackgroundRefresh() {
      if (this.backgroundRefresh) {
        clearInterval(this.backgroundRefresh);
      }
      this.backgroundRefresh = setInterval(
        function (this: any) {
          this.getVehicleLocations();
        }.bind(this),
        this.refreshInterval
      );
    },
    async loadMap() {
      try {
        const google: any = await gmapsInit();
        const geocoder = new google.maps.Geocoder();
        // centre at Munich as default
        this.map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            mapTypeControl: false,
            center: { lat: 48.1351, lng: 11.582 },
            zoom: 12
          }
        );
        if (this.vehiclesLocation.length > 0) {
          this.loadMapMarkers();
          this.showAllMarkers();
        }
      } catch (err) {
        notification.error(err.message);
      }
    },
    selectVehicle() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          selectedVehicle: this.filters.vehicle?.toString()
        }
      });
      this.updateMapMarkers();
    },
    async loadMapMarkers() {
      const google: any = await gmapsInit();
      if (this.vehiclesLocation) {
        // Create markers.
        for (let i = 0; i < this.vehiclesLocation.length; i++) {
          // Create Info Window
          const contentString =
            '<div id="content"><p>' +
            this.vehiclesLocation[i].make +
            " - " +
            this.vehiclesLocation[i].model +
            "</p><p>" +
            this.vehiclesLocation[i].nickname +
            "</p><p>" +
            this.vehiclesLocation[i].registrationNo +
            "</p><br>" +
            "<p>Last updated at:</p>" +
            this.$options.filters?.date(
              this.vehiclesLocation[i].updatedAt,
              "time"
            ) +
            " " +
            this.$options.filters?.date(
              this.vehiclesLocation[i].updatedAt,
              "medium"
            );
          ("</div>");

          const infowindow = new google.maps.InfoWindow({
            content: contentString
          });

          // Store info windows for manipulation later
          this.infoWindows.push(infowindow);

          // Create Marker
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(
              this.vehiclesLocation[i].latitude,
              this.vehiclesLocation[i].longitude
            ),
            title: this.vehiclesLocation[i].registrationNo,
            icon: {
              url: require("@/assets/img/car-icon.svg"),
              scaledSize: new google.maps.Size(50, 50)
            },
            map: this.map
          });

          // Store markers for manipulation later
          this.markers.push(marker);

          // Add listener to marker on click
          this.markers[i].addListener("click", () => {
            this.infoWindows[i].open(this.map, this.markers[i]);
          });
        }
      }
      this.updateMapMarkers();
    },
    async updateMapMarkers() {
      if (this.markers.length > 0) {
        // Update marker selection, location and info windows
        const google: any = await gmapsInit();
        for (let i = 0; i < this.vehiclesLocation.length; i++) {
          if (i == this.filters.vehicle) {
            const icon = {
              url: require("@/assets/img/car-icon-selected.svg"),
              scaledSize: new google.maps.Size(50, 50)
            };
            // change icon of selected vechile to selected
            this.markers[i].setIcon(icon);
            // opening info window of selected vehicle
            this.infoWindows[i].open(this.map, this.markers[i]);
            //panning camera to location of the selected vehicle
            const latlngbounds = new google.maps.LatLngBounds();
            latlngbounds.extend(
              new google.maps.LatLng(
                this.vehiclesLocation[i].latitude,
                this.vehiclesLocation[i].longitude
              )
            );
            this.map.panToBounds(latlngbounds);
            this.map.fitBounds(latlngbounds);
            this.map.setZoom(16);
          } else {
            const icon = {
              url: require("@/assets/img/car-icon.svg"),
              scaledSize: new google.maps.Size(50, 50)
            };
            this.markers[i].setIcon(icon);
            this.infoWindows[i].close(this.map, this.markers[i]);
          }
        }
      }
    },
    async updateMarkerPositions() {
      const google: any = await gmapsInit();
      if (this.markers.length > 0) {
        for (let i = 0; i < this.vehiclesLocation.length; i++) {
          this.markers[i].setPosition(
            new google.maps.LatLng(
              this.vehiclesLocation[i].latitude,
              this.vehiclesLocation[i].longitude
            )
          );
        }
      }
    },
    showAllVehicles() {
      this.filters.vehicle = undefined;
      this.updateMapMarkers();
      this.showAllMarkers();
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          selectedVehicle: undefined
        }
      });
    },
    async showAllMarkers() {
      const google: any = await gmapsInit();
      // Setting map to display all markers via fitBounds()
      const latlngbounds = new google.maps.LatLngBounds();
      for (let i = 0; i < this.vehiclesLocation.length; i++) {
        latlngbounds.extend(
          new google.maps.LatLng(
            this.vehiclesLocation[i].latitude,
            this.vehiclesLocation[i].longitude
          )
        );
      }
      this.map.fitBounds(latlngbounds, { left: 250 });
      this.map.setZoom(this.map.getZoom() - 1);
    }
  }
});
